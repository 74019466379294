<script setup>
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
});

const aspectRatio = computed(() => {
  if (!props.blok?.ratio) {
    return "auto";
  }

  return props.blok.ratio.split("x").join("/");
});

const backgroundImage = computed(() => {
  return `url(${props.blok.image.filename})`;
});
</script>

<template>
  <div
    v-editable="blok"
    :style="{ aspectRatio, backgroundImage }"
    class="overflow-hidden bg-no-repeat bg-cover bg-center"
  >
    <slot></slot>
  </div>
</template>
